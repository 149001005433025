import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import { QlueUIProvider } from "@qlue/ui-kit";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "utils/useAuth";
import { THEME_NAME } from "./configs";

const isProduction = false; // disabled error logging for production
const bugsnagClient = isProduction ? bugsnag(process.env.REACT_APP_BUGSNAG_KEY) : null;

if (isProduction) bugsnagClient.use(bugsnagReact, React);

// enable mocking on development
if (!isProduction) {
  const { worker } = require("./mocks/browser");
  worker.start();
}

function ErrorBoundary({ children }) {
  if (isProduction) {
    const BugsnagBoundary = bugsnagClient.getPlugin("react");
    return <BugsnagBoundary>{children}</BugsnagBoundary>;
  }
  return <Fragment>{children}</Fragment>;
}

function Root() {
  if (isProduction) bugsnagClient.user = { email: "DUMMY@dummy.com" };

  return (
    <ErrorBoundary>
      <QlueUIProvider configName={THEME_NAME}>
        <Provider>
          <App />
        </Provider>
      </QlueUIProvider>
    </ErrorBoundary>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
