const URL = process.env.REACT_APP_API_URL_BASE_API

export const BaseURL = `${URL}/analytics`
export const TotalLeads = `${BaseURL}/total-leads`
export const TotalAccounts = `${BaseURL}/total-accounts`
export const TotalRevenues = `${BaseURL}/total-revenues`
export const LeadStatusPie = `${BaseURL}/lead-status-pie`
export const AccountStatusPie = `${BaseURL}/account-status-pie`
export const LeadGrowth = `${BaseURL}/lead-growth`
export const AccountGrowth = `${BaseURL}/account-growth`

// get owners for filter
export const LeadOwners = `${URL}/leads/owners/filter`
export const AccountOwners = `${URL}/accounts/owners/filter`


// get resource performance
export const GetResourceCounter = `${BaseURL}/resource/counter`
export const GetResourceCompany = `${BaseURL}/resource/company`
export const GetResourceUser = `${BaseURL}/resource/user`
export const GetResourceTask = `${BaseURL}/resource/task`
export const GetResourceCctv = `${BaseURL}/resource/cctv`
export const GetResourceQlueVision = `${BaseURL}/resource/qluevision`
