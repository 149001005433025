import axios from 'axios'
import {
  TOKEN_NAME,
  removeRelatedWithAuth,
  getCookieValue,
  REFRESH_TOKEN_NAME,
  saveToken
} from 'utils/useAuth'
import { AuthURL } from '.'

const instance = axios.create({ timeout: -1 })

instance.interceptors.request.use((config) => {
  const refreshToken = getCookieValue(REFRESH_TOKEN_NAME)
  const token = getCookieValue(TOKEN_NAME)
  config.headers.authorization = token
  config.headers.rft = refreshToken

  return config
})

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config
    if (error.response?.status === 403 && error.response?.config.url !== AuthURL.Login) {
      const refreshToken = originalRequest.headers.rft

      return axios({
        url     : AuthURL.RefreshToken,
        method  : 'post',
        headers : {
          Authorization: refreshToken
        }
      }).then(async (res) => {
        // override token
        originalRequest.headers.authorization = res.data.access_token
        saveToken(res.data) // { access_token, refresh_token }

        return await axios(originalRequest)
      })
    } else if (error.response?.status === 401 && error.response?.config.url !== AuthURL.Login) {
      removeRelatedWithAuth()
      window.location.href = '/'
    } else return Promise.reject(error)
  }
)

export default instance
