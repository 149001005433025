import { XSign } from '@qlue/icon-kit'
import styled from 'styled-components'

export const NotifWrapper = styled.div`
  background-color: ${({ isRead }) => (isRead ? '#fff' : '#f2f9fd')};
  border: solid 1px #e8e8e8;
  color: red;
  padding: 1rem;

  &:hover {
    border: solid 1px #0185d4;
    color: red;
  }

  div {
    cursor: pointer;
  }
`

export const RmvBtn = styled(XSign)`
  display: block;
  margin-left: auto;
  cursor: pointer;
`
