const URL = process.env.REACT_APP_API_URL_BASE_API;

export const BaseProduct = `${URL}/products`;
export const CreateProduct = `${BaseProduct}/add`;
export const ListStatuses = `${BaseProduct}/statuses`;
export const ListTypes = `${BaseProduct}/types`;
export const AllProducts = `${BaseProduct}/all`;
export const AddProducts = `${BaseProduct}/add`;
export const ProductStatuses = `${BaseProduct}/statuses`;
export const ProductTypes = `${BaseProduct}/types`;
export const ProductUnits = `${BaseProduct}/units`;
export const UploadPhotoProduct = `${BaseProduct}/upload`;
export const RetrieveListCurrency = `${BaseProduct}/currencies`;
