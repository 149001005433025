import { useEffect, useState, lazy } from "react";

/**
 * Make sure generate to title case
 * @param {path} str
 */
const toTitleCase = (str) => str.slice(0, 1).toUpperCase() + str.slice(1, str.length);

/**
 * Get default Query Params from path
 *
 * @param {String} path
 */
const getDefaultQueryParams = (path) =>
  import(`../pages${path}/queryParams`)
    .then((m) => m.default)
    .catch(() => {});

/**
 * Use this function to import page from given pathname
 * and remove dynamicSegments from router to match file path
 *
 * @param {String} pathname
 * @param {Object} dynamicSegments
 */
const useImportPage = (pathname, dynamicSegments = {}) => {
  const [page, setPage] = useState({ Component: null, queryParams: {} });
  const { customTarget } = dynamicSegments;

  delete dynamicSegments["*"];

  /**
   * WIP : Simplify logic to getFilePath
   */

  /**
   * UpperCase after slash in pathname
   *
   * From :
   * ./analytic/qluework
   * Into :
   * ./Analytic/Qluework
   *
   * @param {String} str
   */
  const upperCaseSlash = (str) =>
    str
      .split("/")
      .map((x) => toTitleCase(x))
      .join("/");

  /**
   * UpperCase after hyphen in pathname
   *
   * From :
   * ./analytic/qluework/average-completion-time
   *
   * Into :
   * ./analytic/qluework/AverageCompletionTime
   *
   * @param {String} str
   */
  const upperCaseHyphen = (str) =>
    str
      .split("-")
      .map((x) => toTitleCase(x))
      .join("");

  /**
   * Remove Segments in pathname
   *
   * From :
   * ./analytic/qluework/project/:projectId
   *
   * Into :
   * ./analytic/qluework/project
   *
   * @param {String} str
   */
  const dynamicSegment = (str) => {
    const findDynamicSegment = (string) => Object.values(dynamicSegments).find((x) => x === string);
    return str
      .split("/")
      .map((x) => x.replace(findDynamicSegment(x), "DynamicPath"))
      .join("/");
  };

  /**
   * Replace last path with `customTarget`
   *
   * from :
   * ./analytic/qluework/project/
   *
   * Into :
   * ./analytic/qluework/CustomProject
   * @param {String} str
   */
  const replaceLastPath = (str) => {
    if (customTarget) {
      const splitStr = str.split("/");
      splitStr[splitStr.length - 1] = customTarget;

      return splitStr.join("/");
    }

    return str;
  };

  const getFile = replaceLastPath(upperCaseHyphen(upperCaseSlash(dynamicSegment(pathname))));
  const getFilePath = getFile[0] !== "/" ? `/${getFile}` : getFile;

  useEffect(() => {
    (async () => {
      const Component = lazy(() =>
        import(`../pages${getFilePath}`)
          .then((comp) => comp)
          .catch((e) => console.warn(e, "Module not found"))
      );
      const queryParams = await getDefaultQueryParams(getFilePath);
      setPage({ Component, queryParams });
    })();
  }, [customTarget, getFilePath]);

  return page;
};

export default useImportPage;
