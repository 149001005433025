const URL = process.env.REACT_APP_API_URL_BASE_API

export const PostAffiliates = `${URL}/affiliates`
export const GetAffiliatesAll = `${URL}/affiliates/all`
export const GetAffiliateBanks = `${URL}/affiliates/banks`
export const PostAddAffiliate = `${URL}/affiliates/add`
export const PutEditAffiliate = (id) => `${URL}/affiliates/edit/${id}`
export const DeleteAffiliates = `${URL}/affiliates/delete`
export const GetAffiliateStatuses = `${URL}/affiliates/statuses`
export const GetAffiliateRefCode = `${URL}/affiliates/generate-ref-code`

// filter
export const GetAffiliateStatusesFilter = `${URL}/affiliates/statuses/filter`
