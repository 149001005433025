import React, { Fragment, useState, useEffect, memo, useCallback, useMemo } from 'react'
import { Router, Redirect } from '@reach/router'
import { Snackbar, Text } from '@qlue/ui-kit'

import getRoutes, { reMapRoutes, REDIRECT_TO } from './routes'
import { publicRoutes, privateRoutes } from './routes/route'

function useAlert() {
  function Provider({ children }) {
    const [alerts, setAlerts] = useState([])
    const [show, setShow] = useState({})

    let initialBottom = 1

    useEffect(() => {
      window.alert = (message) => {
        setAlerts((prevAlerts) => [message, ...prevAlerts])
      }

      window.alert.notif = (msg) => {
        setAlerts((prevAlerts) => [{ type: 'notif', msg }, ...prevAlerts])
      }

      window.alert.error = (msg) => {
        setAlerts((prevAlerts) => [{ type: 'error', msg }, ...prevAlerts])
      }
    }, [])

    const handleInitializeShow = useCallback(() => {
      alerts.map((alert, i) =>{
        setShow((prevState) => ({
          ...prevState,
          /** 
           * always show newest notif
           * also keep prev notif that haven't closed
          */
          [i]: (i >= Object.keys(prevState).length - 1)  ? true : prevState[i] // keep prev alerts that haven't close
        }))

        return null
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alerts.length])

    useEffect(() => {
      if(alerts.length) {
        handleInitializeShow()
      }  
      else setShow({})

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleInitializeShow])

    const keys = Object.keys(show)
    const isRmvAll = useMemo(() => !keys.find((key) => show[key]), [keys, show])

    useEffect(() => {
      if(keys.length) {
        if(isRmvAll) {
          setAlerts([])
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRmvAll])

    return (
      <Fragment>
        {
          alerts.map((alert, i) => {
            const message = alert?.msg ?? alert
            const type = alert?.type ?? 'success'

            // reset notif position if over 30 rem
            if(initialBottom > 30) {
              initialBottom = 1
            }
            else initialBottom = initialBottom + 3.5

            return (
              <Snackbar
                key={ i }
                type={ type }
                bottom={ `${initialBottom}rem` }
                show={ show[i] }
                handleClose={ () => {
                  setShow((prevState) => ({
                    ...prevState,
                    [i]: false
                  }))
                } }
              >
                <Text>{ message }</Text>
              </Snackbar>
            )
          })
        }
        { children }
      </Fragment>
    )
  }

  return { Provider }
}

function App() {
  const publicRoute = getRoutes(publicRoutes, 'public')
  const privateRoute = getRoutes(reMapRoutes(privateRoutes), 'private')
  const { Provider } = useAlert()

  return (
    <Provider>
      <Router>
        <WrapperComponent path="/">
          { publicRoute }
          { privateRoute }
          <Redirect from="*" to={ REDIRECT_TO } noThrow />
        </WrapperComponent>
      </Router>
    </Provider>
  )
}

function WrapperComponent({ children }) {
  return <Fragment>{ children }</Fragment>
}

export default memo(App)
