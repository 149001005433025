import React from "react";

import { version } from "../../../package.json";
import { Base } from "./styles";

const isProduction = process.env.REACT_APP_NODE_ENV === "production";

const FloatingVersion = () => {
  if (isProduction) return <Base>vCRM-{version}</Base>;
  return null;
};

export default FloatingVersion;
