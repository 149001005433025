import React from 'react'
import { navigate } from '@reach/router'
import { format as formatDate } from 'date-fns'
import { 
  Text, 
  Heading, 
  Flex, 
  Space 
} from '@qlue/ui-kit'
import { NotifWrapper, RmvBtn } from './styles'

const NotificationCard = ({ 
  updatedNotif, 
  handleRmvNotif, 
  handleMarkReadNotif 
}) => (
  updatedNotif.map((notif, id) => (
    <NotifWrapper 
      key={ `notif-${id}` } 
      isRead={ notif.is_read }
    >
      <RmvBtn onClick={ () => handleRmvNotif([notif.id]) } />

      <div
        onClick={ () => {
          navigate(`/${notif.redirect_route}`)

          if (!notif.is_read) {
            handleMarkReadNotif(notif.id)
          }
        } }
      >
        <Heading as='h4'>
          { notif.title }
        </Heading>

        <Space bottom={ 1 } />

        <Flex justifyContent='space-between'>
          <Text>
            { notif.body }
          </Text>

          <Space right={ 1 } />

          <Text color='text-02'>
            { formatDate(new Date(notif.created_at), 'dd/MM/yyyy') }
          </Text>
        </Flex>
      </div>
    </NotifWrapper>
  ))
)

export default NotificationCard
