const encryptToken = (token) => {
  // 1. convert to array
  const tokenArr = token.split('')

  // 2. reverse array
  const reverseTokenArr = tokenArr.reverse()

  // 3. convert to list of ASCII
  const listASCII = reverseTokenArr.map((letter) => letter.charCodeAt(0))

  // 4. generate new list ASCII -> newASCII = currentASCII + index%2
  const newListASCII = listASCII.map((code, index) => code + (index % 2))

  // 5. convert new list ASCII to list alphabet
  const listAlphabet = newListASCII.map((code) => String.fromCharCode(code))

  // 6. convert listAlphabet to formatted string token
  const encryptedToken = listAlphabet.join('')

  return encryptedToken
}

export default encryptToken