const URL = process.env.REACT_APP_API_URL_BASE_API

export const BaseContent = `${URL}/contents`
export const CreateContent = `${BaseContent}/add`
export const UploadContent = `${BaseContent}/upload`
export const ContentStatus = `${BaseContent}/statuses`
export const ContentDetail = `${BaseContent}/cf`
export const StatusFilter = `${ContentStatus}/filter`
export const ContentAuthor = `${BaseContent}/authors`
export const AuthorFilter = `${ContentAuthor}/filter`
export const GetContentCategories = `${BaseContent}/categories`
