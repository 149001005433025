import React, { createContext, useEffect, useReducer, useContext } from 'react'
import { setCookies } from 'utils'
import encryptToken from './encryptToken'

// constant name
export const TOKEN_NAME = 'access_token'
export const REFRESH_TOKEN_NAME = 'rft_crm'
export const DETAIL_USER = 'detail-user'
const DASHBOARD_TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME
const DASHBOARD_REFRESH_TOKEN_NAME = process.env.REACT_APP_REFRESH_TOKEN_NAME
const DASHBOARD_TRIGGER_STORAGE = process.env.REACT_APP_TRIGGER_STORAGE

const ContextAuth = createContext()
const initialState = {
  user         : null,
  token        : getCookieValue(TOKEN_NAME),
  refreshToken : getCookieValue(REFRESH_TOKEN_NAME)
}

// get value of cookie
export function getCookieValue(name) {
  const getCookie = document.cookie.split('; ').find((row) => row.startsWith(name))

  if (getCookie) return getCookie.split('=')[1]
  else return null
}

export function removeRelatedWithAuth() {
  const cookies = document.cookie.split(';')

  // delete cookies token
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie

    document.cookie = `${name}=;Max-Age=0;path=/;`
  }

  // remove data profile
  localStorage.removeItem(DETAIL_USER)
}

export function saveToken({ access_token, refresh_token }) {
  document.cookie = `${TOKEN_NAME}=${access_token}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;`
  document.cookie = `${REFRESH_TOKEN_NAME}=${refresh_token}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;`
}

export function saveTokenDashboard({ access_token, refreshToken, ...state }) {
  setCookies([
    [DASHBOARD_TOKEN_NAME, encryptToken(access_token)],
    [DASHBOARD_REFRESH_TOKEN_NAME, encryptToken(refreshToken)],
    [DASHBOARD_TRIGGER_STORAGE, JSON.stringify(state)]
  ])
}

function reducer(state, { key, ...payload }) {
  switch (key) {
    case 'SET_TOKEN':
      return {
        ...state,
        token        : payload.token,
        refreshToken : payload.refreshToken
      }
    case 'SET_DETAIL_USER':
      return {
        ...state,
        user: { ...payload.data }
      }
    default:
      return state
  }
}

export function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const getDetailUser = localStorage.getItem(DETAIL_USER)
    if (getDetailUser) {
      const dataUser = JSON.parse(getDetailUser)
      dispatch({ key: 'SET_DETAIL_USER', data: { ...dataUser } })
    }
  }, [])

  return <ContextAuth.Provider value={{ state, dispatch }}>{ children }</ContextAuth.Provider>
}

export function useStoreAuth() {
  const context = useContext(ContextAuth)
  if (!context) throw new Error('Connot using auth data whereas outside provider auth')

  return context
}
