import APIConfig from './config'

import * as AuthURL from './URLs/auth'
import * as LeadManagementURL from './URLs/leadManagement'
import * as UserManagementURL from './URLs/userManagement'
import * as AccountManagementURL from './URLs/accountManagement'
import * as ContentManagementURL from './URLs/contentManagement'
import * as ProductManagementURL from './URLs/productManagement'
import * as PackageManagementURL from './URLs/packageManagement'
import * as TransactionURL from './URLs/transaction'
import * as AnalyticURL from './URLs/analytic'
import * as AffiliateURL from './URLs/affiliate'
import * as RequestJoinAffiliateURL from './URLs/requestJoinAffiliate'
import * as AffiliatePayoutURL from './URLs/affiliatePayout'
import * as NotificationURL from './URLs/notification'
import * as LogsURL from './URLs/logs'
import * as ServerURL from './URLs/server'

export default APIConfig
export {
  TransactionURL,
  AuthURL,
  LeadManagementURL,
  UserManagementURL,
  AccountManagementURL,
  ProductManagementURL,
  ContentManagementURL,
  PackageManagementURL,
  AnalyticURL,
  AffiliateURL,
  RequestJoinAffiliateURL,
  AffiliatePayoutURL,
  NotificationURL,
  LogsURL,
  ServerURL
}
