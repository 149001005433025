import API from 'utils/API'
import { version } from '@qlue/saas-website/package.json'
import { isLocalhost } from 'serviceWorker'
const domain = !isLocalhost ? 'domain=qlue.ai' : ''
/**
 * @param {number} number - Number of currency
 * @param {Object} configs
 * @param {boolean} configs.removeCurrency - Remove currency code
 * @param {string} configs.currency - Replace currency code, default IDR
 * @param {boolean} configs.removeIDR - Deprecated: Remote currency IDR
 *
 * @return {string}
 */
export function formatMoney(number, configs) {
  const removeCurrency = !!configs?.removeCurrency
  const currency = configs?.currency || 'IDR'
  const removeIDR = !!configs?.removeIDR
  const regexCurrency = new RegExp(currency, 'gi')

  const country = currency.toUpperCase() === 'IDR' ? 'id-ID' : 'en-US'

  let formatedMoney = new Intl.NumberFormat(country, {
    currency,
    style                 : 'currency',
    currencyDisplay       : 'code',
    minimumFractionDigits : 0
  }).format(number)

  if (removeCurrency || removeIDR) {
    formatedMoney = formatedMoney.replace(regexCurrency, '').trim()
  }

  return formatedMoney
}

export function triggerReBuildSaaSWebsite() {
  return new Promise((resolve, reject) => {
    const isProduction = process.env.REACT_APP_NODE_ENV === 'production'
    const isStaging = process.env.REACT_APP_NODE_ENV === 'staging'

    const body = new FormData()

    let env

    if (isProduction) {
      env = `refs/tags/CF-${version}`
    } else if (isStaging) {
      env = 'staging'
    } else {
      env = 'development'
    }

    body.append('token', '3e7d8c05168cb0b6e7b2950be8bf63')
    body.append('ref', env)
    body.append('variables[TRIGGER_FROM_API]', 'true')

    API({
      method  : 'post',
      headers : { 'Content-Type': 'multipart/form-data' },
      url     : 'https://gitlab.qlue.ai/api/v4/projects/80/trigger/pipeline',
      data    : body
    })
      .then(resolve)
      .catch(reject)
  })
}

export function formatXlabel(selectedPeriod) {
  if (selectedPeriod.toLowerCase() === 'daily') return 'Day'
  else if (selectedPeriod.toLowerCase() === 'weekly') return 'Week'
  else return 'Month'
}

/**
 * @param {Array} payload
 */
export function setCookies(payload) {
  if (!Array.isArray(payload)) throw new Error(`payload must be an array, expected: ${typeof payload}`)
  else {
    payload.forEach(([key, value]) => {
      if (key) {
        document.cookie = `${key}=${value}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT; ${domain}`
      }
    })
  }
}

// setError state while value is empty
export const getNoValueCondition = ({ condition = false, key = '', text = '' }, setError, result) => {
  if (condition) {
    setError((prev) => ({
      ...prev,
      [key]: `Please fill in ${text || key} field`
    }))
    result = true
  }
}