import React, { 
  useCallback, 
  useEffect, 
  useState 
} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useStore } from 'pages'
import { IconTrash } from '@qlue/icon-kit'
import { 
  Flex, 
  Heading, 
  Space, 
  Text,
  Shimmer 
} from '@qlue/ui-kit'
import NotificationCard from '../Card'
import { 
  NotifBar, 
  CloseBtn, 
  DeleteAll, 
  MarkAllRead 
} from './styles'
import API, { NotificationURL } from 'utils/API'

const NotificationBar = () => {
  const { 
    isOpenNotif,
    newNotif,
    setIsOpenNotif,
    setUnreadNotif
  } = useStore()

  const [updatedNotif, setUpdatedNotif] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(2)

  const fetchNotifList = useCallback(async (page) => {
    await API.post(`${NotificationURL.PostNotifcationList}?page=${page}&per_page=10`)
      .then((response) => {
        const notifList = response.data.notifications

        const totalPage = response.data.meta.total_page
        setTotalPage(totalPage)

        if (page === 1) {
          setUpdatedNotif([...notifList])
        } else {
          setUpdatedNotif((updatedNotif) => [...updatedNotif, ...notifList])
        }
      })
      .catch((error) => {
        if(error?.response?.status !== 403) alert.error(error?.response?.data?.message)
      })
      .finally(() => setIsLoading(false))
  }, [])

  const handleRmvNotif = async (id) => {
    // we can do bulk delete in the future with this API
    await API.delete(NotificationURL.DeleteSomeNotif, {
      data: { notification_ids: id }
    })
      .then(() => {
        setUpdatedNotif((prev) => {
          const tempNotifList = prev.filter((notif) => notif.id !== id.join(''))
          return tempNotifList
        })
      })
      .catch((error) => {
        alert.error(error.response.data.message)
      })
  }

  const handleRmvAllNotif = async () => {
    await API.delete(NotificationURL.DeleteAllNotif)
      .then(() => {
        setUnreadNotif(false)
        fetchNotifList(1)
        setPage(1)
        alert('Successfully delete all notifications')
      })
      .catch((error) => {
        alert.error(error.response.data.message)
      })
  }

  const handleMarkReadNotif = async (id) => {
    await API.put(NotificationURL.PutMarkReadNotif(id))
      .then(() => setUnreadNotif(false))
  }

  const handleMarkReadAllNotif = async () => {
    await API.put(NotificationURL.PutMarkReadAllNotif)
      .then(() => {
        fetchNotifList(1)
        setUnreadNotif(false)
        alert('Successfully read all notifications')
      })
      .catch((error) => alert.error(error.response.data.message))
  }

  useEffect(() => {
    if (isOpenNotif) {   
      fetchNotifList(page)  
    }
    else setPage(1)

  }, [fetchNotifList, isOpenNotif, page])

  useEffect(() => {
    if(newNotif.length) {
      const lastNewNotif = newNotif.reverse().pop()

      // if notif bar open, push new notif to current data
      if (isOpenNotif) {
        setUpdatedNotif((prevValue) => [lastNewNotif, ...prevValue])
      }
      // else refetch notif list
      else {
        fetchNotifList(1)
        setPage(1)
      }
    }
  }, [fetchNotifList, isOpenNotif, newNotif])

  const NotifListCard = () => (
    updatedNotif.length 
      ? (
        <InfiniteScroll
          dataLength={ updatedNotif.length }
          next={ () => setPage(page + 1) } 
          hasMore={ page < totalPage }
          loader={ <Text color='text-02'>Load More...</Text> }
          scrollableTarget='scrollableDiv'
        >
          <NotificationCard
            updatedNotif={ updatedNotif }
            handleMarkReadNotif={ handleMarkReadNotif }
            handleRmvNotif={ handleRmvNotif }
          />
        </InfiniteScroll>
      ) 
      : <Text color='text-02'>Notification is empty</Text>
  )

  return (
    <NotifBar
      // inifinite scroll reference height
      id='scrollableDiv'
      isOpenNotif={ isOpenNotif }
    >
      <Flex 
        justifyContent='space-between' 
        alignItems='center'
      >
        <Heading as='h2'>
          Notification
        </Heading>
        <CloseBtn onClick={ () => setIsOpenNotif(!isOpenNotif) } />
      </Flex>
      { 
        isLoading 
          ? (
            <>
              <Shimmer />
              <Shimmer />
              <Shimmer />
              <Shimmer />
              <Shimmer />
              <Shimmer />
            </>
          )
          : (
            <>
              <Space top={ 4 } />

              <Flex justifyContent='space-between'>
                <DeleteAll isEmpty={ !updatedNotif.length } onClick={ handleRmvAllNotif }>
                  <IconTrash />
                  <Space right={ 2 } />
                  <Text color='text-02'>Delete all</Text>
                </DeleteAll>

                <MarkAllRead
                  isReadAll={ !updatedNotif.filter((notif) => !notif.is_read).length }
                  onClick={ handleMarkReadAllNotif }
                >
                  <Text>Mark all as read</Text>
                </MarkAllRead>
              </Flex>

              <Space bottom={ 3 } />
            
              <NotifListCard />
            </>
          ) 
      }
    </NotifBar>
  )
}

export default NotificationBar
