/**
 * use custom hooks using library from here
 * https://github.com/tannerlinsley/react-query
 */
import { useQuery } from 'react-query'
import API from 'utils/API'

const configs = {
  refetchOnWindowFocus : false,
  retry                : 1
}

function useFetch({ url = '', params = {}, initialData = {}, method = 'get' }) {
  
  async function asyncFunc() {
    try {
      const isPostMethod = method.search(/post/gi) > -1
      const configAPI = !isPostMethod ? { params } : { ...params }

      const fetchAPI = await API[method](url, configAPI)

      return fetchAPI.data
    } catch (err) {
      if (err?.response && err?.response?.status !== 403) alert.error(err.response.data.message)
      else if (err?.response && err?.response?.status === 403) {
        throw err
      }
    }
  }

  const { isFetching, data, error, ...query } = useQuery(url, asyncFunc, configs)
  const resultData = !data ? initialData : data

  return { isLoading: isFetching, data: resultData, error, ...query }
}

export default useFetch
