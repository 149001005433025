import styled from 'styled-components'
import { XSign } from '@qlue/icon-kit'

export const NotifBar = styled.div`
  background: #fff;
  z-index: 1000;
  height: 100vh;
  top: 0;
  position: absolute;
  display: ${({ isOpenNotif }) => (isOpenNotif ? 'block' : 'none')};
  width: 40vw;
  overflow-y: auto;
  padding: 1.5rem;
`

export const CloseBtn = styled(XSign)`
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
`

export const DeleteAll = styled.div`
  display: flex;
  align-item: center;
  cursor: pointer;
  opacity: ${({ isEmpty }) => (isEmpty ? 0.5 : 1)};
  pointer-events: ${({ isEmpty }) => (isEmpty ? 'none' : 'unset')};
`

export const MarkAllRead = styled.div`
  > span {
    color: #0185d4 !important;
    opacity: ${({ isReadAll }) => (isReadAll ? 0.5 : 1)};
  }
  cursor: pointer;
  pointer-events: ${({ isReadAll }) => (isReadAll ? 'none' : 'unset')};
`
