const URL = process.env.REACT_APP_API_URL_BASE_API

export const BaseAccounts = `${URL}/accounts`
export const CreateAccounts = `${BaseAccounts}/add`
export const ListDealLeads = `${BaseAccounts}/leads`
export const ListStatuses = `${BaseAccounts}/statuses`
export const UploadAccount = `${BaseAccounts}/upload`
export const ListOwners = `${BaseAccounts}/owners`
export const ListPackages = `${BaseAccounts}/packages`
export const ListStatusesFilter = `${BaseAccounts}/statuses/filter`
export const ListOwnersFilter = `${BaseAccounts}/owners/filter`
export const ListPackagesFilter = `${BaseAccounts}/packages/filter`
export const ListBillingStatusesFilter = `${BaseAccounts}/billing-statuses/filter`
export const ListBillingType = `${BaseAccounts}/billing-type`
export const GetEndDateBilling = `${BaseAccounts}/billing-end-date`
export const PostAccountsExcel = `${BaseAccounts}/excel`
export const PostAccountClientAffiliateList = (id) => `${BaseAccounts}/client-affiliate/${id}`
