import { useEffect } from "react";

import qs from "query-string";
import { navigate } from "@reach/router";

export default function useQueryParams({ search = {}, defaultQuery = {} }) {
  const queryParams = qs.parse(search);

  /** Spread default queries and spread current query params */
  const queryResult = { ...defaultQuery, ...queryParams };

  /** filter queryParams object keys with the keys in defaultQuery object */
  const queryKeys = Object.keys(queryResult);
  const filterQuery =
    queryKeys.length !== 0
      ? queryKeys.reduce((sum, item) => ({ ...sum, [item]: queryResult[item] }), {})
      : queryResult;

  const stringifyQuery = qs.stringify(filterQuery);

  useEffect(() => {
    const state = window.history.state;
    navigate(`?${stringifyQuery}`, { state, replace: true });
  }, [stringifyQuery]);

  return filterQuery;
}
