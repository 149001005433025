const URL = process.env.REACT_APP_API_URL_BASE_API

export const PostAffiliatesPayout = `${URL}/affiliates/payout`
export const GetAffiliatePayoutStatusesFilter = `${URL}/affiliates/payout-statuses/filter`
export const GetAffiliatePayoutClients = (id) => `${URL}/affiliates/payout-clients/${id}`
export const GetAffiliatePayoutStatuses =`${URL}/affiliates/payout-statuses`
export const PostAddAffiliatePayout = `${URL}/affiliates/payout/add`
export const GetPayoutDetail = (id) => `${URL}/affiliates/payout-detail/${id}`
export const DeletePayout = `${URL}/affiliates/delete-payout`
export const PutPayout = (id) => `${URL}/affiliates/payout/${id}`
