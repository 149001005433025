import styled, { css } from 'styled-components'
import { THEME } from 'configs'
import { IconBell as IconBellUI } from '@qlue/icon-kit'

export const BaseTitle = styled.div`
  margin-bottom: 0.8em;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 0.5px solid ${THEME.colors['bg-04']};
  position: relative;
`

export const Tabs = styled.div`
  overflow: hidden;
  position: absolute;
  right: 2em;
  top: 12px;
`

export const Option = styled.button`
  background-color: ${THEME.colors['bg-02']};
  float: right;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${(props) => (props.active ? 'black' : '#a3a3a3')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  padding: 10px;
  margin-left: 10px;
  border-bottom: ${(props) => (props.active ? '3px solid #0185d4' : 'white')};
`

export const ArrowBack = styled.div`
  margin-right: 20px;

  div:hover {
    cursor: pointer;
    color: ${(props) => (props.active ? 'black' : '#a3a3a3')};
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    border-bottom: ${(props) => (props.active ? '3px solid #0185d4' : 'white')};
    transition: 0.3s;
  }
`

export const EmptyAvatar = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 50%;
  border: solid 0.5px ${THEME.colors['text-02']};
  color: ${THEME.colors['text-01']};

  ${({ isBigger }) => isBigger &&
    css`
      width: 100%;
      height: 100%;
      background-color: #fff;
    `}
`

export const BaseStaticMenu = styled.div`
  text-align: center;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 12px;
  margin-left: 4px;
  position: relative;
`

BaseStaticMenu.Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const IconWrapper = styled.div`
  position: relative;
`

export const RedDot = styled.div`
  top: 0;
  left: 0.5em;
  position: absolute;
  border-radius: 50%;
  background: red;
  width: 10px;
  height: 10px;
  display: ${({ isDisplay }) => isDisplay === 'display' ? 'block' : 'none'};
`

export const IconBell = styled(IconBellUI)`
  margin-left: -0.35em;
`

export const DateWrapper = styled.div`
  margin-left: 1rem;
`
export const FlexAuto = styled.div`
  display: flex;
  flex: auto;
`