import RenderPage from 'pages'

export const publicRoutes = [
  {
    path      : 'login',
    component : RenderPage
  },
  {
    path      : 'user-activation',
    component : RenderPage
  }
]

export const privateRoutes = [
  {
    path      : 'home',
    useTitle  : 'Home',
    useTab    : [{ name: 'Server Resource' }, { name: 'Performance' }],
    component : RenderPage,
    children  : [
      {
        path      : 'performance',
        liftingUp : 1,
        useTab    : [{ name: 'Server Resource' }, { name: 'Performance' }],
        useTitle  : 'Home',
        route     : 'home',
        component : RenderPage
      },
      {
        path      : 'server-resource',
        liftingUp : 1,
        useTab    : [{ name: 'Server Resource' }, { name: 'Performance' }],
        useTitle  : 'Home',
        route     : 'home',
        component : RenderPage
      }
    ]
  },
  {
    path      : 'lead',
    useTitle  : 'Lead',
    component : RenderPage
  },
  {
    path      : 'user',
    useTitle  : 'User',
    component : RenderPage
  },
  {
    path      : 'account',
    useTitle  : 'Account',
    component : RenderPage
  },
  {
    path              : 'account/transactions',
    useTitle          : 'Account Transaction',
    component         : RenderPage,
    hasBackNavigation : true
  },

  {
    path      : 'transaction',
    useTitle  : 'Transaction',
    component : RenderPage
  },
  {
    path      : 'content',
    useTitle  : 'Content',
    component : RenderPage,
    children  : [
      {
        path              : 'create',
        liftingUp         : 1,
        component         : RenderPage,
        useTitle          : ' ',
        hasBackNavigation : true
      }
    ]
  },
  {
    path      : 'affiliate',
    useTitle  : 'Affiliate',
    route     : 'affiliate',
    useTab    : [{ name: 'Payout' }, { name: 'Request Join' }, { name: 'List Affiliate' }],
    component : RenderPage,
    children  : [
      {
        path      : 'list-affiliate',
        liftingUp : 1,
        useTab    : [{ name: 'Payout' }, { name: 'Request Join' }, { name: 'List Affiliate' }],
        useTitle  : 'Affiliate',
        route     : 'affiliate',
        component : RenderPage
      },
      {
        path      : 'request-join',
        liftingUp : 1,
        useTab    : [{ name: 'Payout' }, { name: 'Request Join' }, { name: 'List Affiliate' }],
        useTitle  : 'Affiliate',
        route     : 'affiliate',
        component : RenderPage
      },
      {
        path      : 'payout',
        liftingUp : 1,
        useTab    : [{ name: 'Payout' }, { name: 'Request Join' }, { name: 'List Affiliate' }],
        useTitle  : 'Affiliate',
        route     : 'affiliate',
        component : RenderPage
      }
    ]
  },
  {
    path              : 'affiliate/clients',
    useTitle          : 'Affiliate Client',
    component         : RenderPage,
    hasBackNavigation : true
  },
  {
    path      : 'plan',
    useTitle  : 'Plan',
    route     : 'plan',
    useTab    : [{ name: 'Product' }, { name: 'Package' }],
    component : RenderPage,
    children  : [
      {
        path      : 'package',
        liftingUp : 1,
        useTab    : [{ name: 'Product' }, { name: 'Package' }],
        useTitle  : 'Plan',
        route     : 'plan',
        component : RenderPage
      },
      {
        path      : 'product',
        liftingUp : 1,
        useTab    : [{ name: 'Product' }, { name: 'Package' }],
        useTitle  : 'Plan',
        route     : 'plan',
        component : RenderPage
      }
    ]
  },
  {
    path      : 'logs',
    useTitle  : 'Logs',
    component : RenderPage
  },
  {
    path      : 'server',
    useTitle  : 'Server',
    component : RenderPage,
    children  : [
      {
        path              : 'detail',
        liftingUp         : 1,
        component         : RenderPage,
        useTitle          : 'Server Detail',
        hasBackNavigation : true
      },
      {
        path              : 'create',
        liftingUp         : 1,
        component         : RenderPage,
        useTitle          : ' ',
        hasBackNavigation : true
      }
    ]
  }
]
