import React, {
  memo,
  Suspense,
  useState,
  useContext,
  useMemo,
  createContext,
  Fragment,
  useCallback,
  useEffect
} from 'react'
import { Sidebar, Heading } from '@qlue/ui-kit'
import { navigate } from '@reach/router'
import { 
  IconQlueCrm,
  IconLogout, 
  ArrowLeftLong 
} from '@qlue/icon-kit'
import useQueryParams from 'utils/useQueryParams'
import useImportPage from 'utils/useImportPage'
import { 
  getCookieValue, 
  removeRelatedWithAuth, 
  TOKEN_NAME, useStoreAuth
} from 'utils/useAuth'
import { 
  BaseTitle, 
  Tabs, 
  Option, 
  BaseStaticMenu, 
  ArrowBack,
  IconBell,
  IconWrapper,
  RedDot
} from './styles'
import useFetch from 'utils/useFetch'
import FloatingVersion from 'components/FloatingVersion'
import { NotificationBar } from 'components/Notification'
import API, { AuthURL, NotificationURL } from 'utils/API'

const ChildTabContext = createContext()

const getIconName = {
  Home        : 'IconHome',
  Lead        : 'IconParticle',
  Account     : 'Contact',
  Plan        : 'IconBag',
  Transaction : 'IconTransaction',
  Content     : 'IconContent',
  User        : 'IconUser',
  Affiliate   : 'IconPeople',
  Reports     : 'IconDoc',
  Server      : 'IconServer'
}

const NotifIcon = ({ 
  isOpenNotif,
  unreadNotif 
}) => (
  <BaseStaticMenu>
    <IconWrapper>
      <IconBell
        width='23px' 
        height='23px'
      />
      <RedDot isDisplay={ unreadNotif && !isOpenNotif ? 'display' : 'hide' }/> 
    </IconWrapper>
    <BaseStaticMenu.Text>
      Notification
    </BaseStaticMenu.Text>
  </BaseStaticMenu>
)

function ProfileSidebar() {
  const { dispatch } = useStoreAuth()

  function onLogoutClick(e) {
    removeRelatedWithAuth()
    window.sessionStorage.removeItem('sseId')
    dispatch({ key: 'SET_TOKEN', token: '', refreshToken: '' })
    navigate('/')
  }

  return (
    <BaseStaticMenu onClick={ onLogoutClick }>
      <IconLogout width="18px" height="18px" />
      <BaseStaticMenu.Text>Logout</BaseStaticMenu.Text>
      <FloatingVersion />
    </BaseStaticMenu>
  )
}

const TabContent = ({ tab, last, location }) => {
  const onTabClick = (content) => {
    navigate(`/${last}/${content.split(' ').join('-').toLowerCase()}`)
  }

  return (
    <Tabs>
      { tab.map((el, idx) => (
        <Option
          key={ idx }
          active={ location.pathname.split('/')[2] === el.name.split(' ').join('-').toLowerCase() }
          onClick={ () => {
            onTabClick(el.name)
          } }
        >
          { el.name }
        </Option>
      )) }
    </Tabs>
  )
}

function Content({
  totalPathReturn,
  location,
  useTitle,
  hasBackNavigation,
  children,
  uri,
  useTab,
  route,
  ...restProps
}) {
  const { search, pathname } = location
  const [title, setTitle] = useState(useTitle)

  const { Component, queryParams: defaultQuery } = useImportPage(pathname, restProps)
  const queryParams = useQueryParams({ search, defaultQuery })
  const restPropsWithQueryParams = { ...restProps, queryParams, setTitle }

  const suspenseComponent = (
    <Suspense fallback={ <div /> }>
      { Component && <Component { ...restPropsWithQueryParams } /> }
    </Suspense>
  )

  if (useTitle)
    return (
      <ContentWithTitle
        { ...{
          title,
          location,
          totalPathReturn,
          setTitle,
          hasBackNavigation,
          useTitle,
          useTab,
          route
        } }
      >
        { suspenseComponent }
      </ContentWithTitle>
    )
  else return suspenseComponent
}

function ContentWithTitle({
  title,
  location,
  totalPathReturn,
  setTitle,
  hasBackNavigation,
  useTitle,
  useTab,
  route,
  children
}) {

  const [isOpenNotif, setIsOpenNotif] = useState(false)
  const [unreadNotif, setUnreadNotif] = useState(false)
  const [refetchNotif, setRefetchNotif] = useState(false)
  const [newNotif, setNewNotif] = useState([])

  useEffect(() => {
    /**
     * BE always close unused connection so we will not face memory leak and only keep last connection to be open,
     * but allows for multiple connections on different browser tabs,
     * so FE need to send first sseId as flagging to BE
    */
    // refecth when isOpenNotif to prevent token expired when user open the notif bar
    const savedSseId = window.sessionStorage.getItem('sseId')
    const token = getCookieValue(TOKEN_NAME)
    
    if (token) {
      const body = savedSseId || ''

      /**
       * Resolved Problems [1]
       * pages/README.md
       */
      API.post(NotificationURL.PostGetSseId, {
        sseId: body
      })
        .then((response) => {
          const sseId = response.data.sseId
          if(sseId) window.sessionStorage.setItem('sseId', sseId)
          // const tokenHeader = response.config.headers.authorization.split('Bearer ').join('')

          const sseURL =`${NotificationURL.GetRealTimeNotif}?token=${token.split('Bearer ').join('')}&sseId=${sseId}` 
          const events =  new EventSource(sseURL)
           
          events.onmessage = (event) => {
      
            const parsedData = JSON.parse(event.data)

            // if notif data
            if (!parsedData.message) {
              setNewNotif((prevValue) => [parsedData, ...prevValue])
              setRefetchNotif(true)
              setUnreadNotif(true)
              alert.notif(parsedData.title)
            }
          }

          events.onerror = () => {
            console.log('error fetch notif')
          }

        })
    }
  }, [])

  const onClickMenu = (e) => {
    if (e.name && e.name !== 'Notification') navigate(`/${e.name.toLowerCase()}`)
    else if (e.name && e.name === 'Notification') {
      setIsOpenNotif((prevValue) => {
        if(prevValue) setUnreadNotif(false)
        return !prevValue
      })
    }
  }

  const { data: menus } = useFetch({
    url         : AuthURL.GetMenus,
    initialData : []
  })

  const getStaticMenus = useMemo(() => (
    [
      {
        name          : 'Notification',
        customizeWith : (
          <NotifIcon 
            isOpenNotif={ isOpenNotif }
            unreadNotif={ unreadNotif }
          />
        ),
        moveBottom: true
      },
      {
        moveBottom    : true,
        marginTop     : '-13px',
        customizeWith : <ProfileSidebar />
      }
    ]
  ), [isOpenNotif, unreadNotif])

  const MENUS = useMemo(
    () => [
      ...menus.map((menu) => ({ ...menu, iconName: getIconName[menu.name] })),
      ...getStaticMenus
    ],

    [getStaticMenus, menus]
  )

  const activeMenu = useMemo(
    () => MENUS.findIndex((menu) => menu?.name?.toLowerCase() === location.pathname.split('/')[1]) + 1,
    [location.pathname, MENUS]
  )

  const handleReturn = useCallback(() => {
    if (totalPathReturn > 1) {
      let backPath = '../'
      for (let i = 1; i < totalPathReturn; i += 1) {
        backPath += backPath
      }
      navigate(backPath)
    } else window.history.back()
  }, [totalPathReturn])

  return (
    <ChildTabContext.Provider
      value={{
        title,
        newNotif,
        isOpenNotif,
        refetchNotif,
        setTitle,
        setIsOpenNotif,
        setUnreadNotif,
        setRefetchNotif
      }}
    >
      <Sidebar
        mainTitle="The Title"
        menus={ MENUS }
        activeMenu={ activeMenu }
        onClickMenu={ onClickMenu }
        logoURL={ <IconQlueCrm width="100%" height="100%" /> }
      >
        { /* pop up notification bar */ }
        <NotificationBar />

        <BaseTitle>
          { hasBackNavigation ? (
            <Fragment>
              <ArrowBack>
                <div onClick={ handleReturn }>
                  <ArrowLeftLong height="20px" width="20px" fill="grey" />
                </div>
              </ArrowBack>
              <Heading>{ title }</Heading>
            </Fragment>
          ) : (
            <Fragment>
              <Heading>{ useTitle }</Heading>
            </Fragment>
          ) }
          { useTab && <TabContent tab={ useTab } last={ route } location={ location } /> }
        </BaseTitle>
        { children }
      </Sidebar>
    </ChildTabContext.Provider>
  )
}

export function useStore() {
  const store = useContext(ChildTabContext)
  if (!store) throw new Error('Cannot using this store')

  return store
}

export default memo(Content)
